body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #023A40;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.navbar-brand {
  font-family: 'Gugi', sans-serif;
  font-size: 1.75rem;
}

.navbar-logo {
  width: 40px;
  height: auto;
}

.top-header {
  padding-top: 60px;
}

.landing {
  line-height: 1.6;
}

section {
  flex: 1;
}

.section-text {
  padding-top: 30px;
}

section p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.25rem; /* Adjust the size as needed */
  line-height: 1.6;
  color: #d3d3d3; /* Light gray color */
}

footer {
  background-color: #023A40 !important;
  padding: 20px 0;
}

.popping-image {
  width: 110%;
  max-width: none;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  transform: translateY(-10px) translateX(10%);
  transition: transform 0.3s, box-shadow 0.3s;
}

.popping-image:hover {
  transform: translateY(-20px) translateX(10%);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 16px 32px rgba(0, 0, 0, 0.2);
}

.bg-dark {
  background-color: #023A40 !important;
}

.ada-font {
  font-family: 'Gugi', Arial, sans-serif;
}

.custom-modal-header {
  background-color: #028185;
  color: white;
  border-bottom: 2px dashed #05B6B7;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.custom-modal-header .modal-title {
  flex-grow: 1;
  text-align: center;
}

.custom-modal-header .btn-close {
  filter: invert(1);
  position: absolute;
  right: 1rem;
}

.custom-modal-body {
  background-color: #028185;
  color: white;
}

.custom-submit-button {
  background-color: #05B6B7;
  border-color: #05B6B7;
  display: block;
  margin: 0 auto;
}

.custom-submit-button:hover {
  background-color: #01BFBF;
  border-color: #01BFBF;
}

.custom-modal-body .form-label {
  color: white;
}

.custom-input {
  background-color: #f0f0f0;
  border-color: #ccc;
  color: #333;
}

.custom-input:focus {
  background-color: #e0e0e0;
  border-color: #bbb;
}

.join-team-link {
  color: #05B6B7;
  text-decoration: underline;
}

.join-team-link:hover {
  color: #01BFBF;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar-brand {
    font-size: 1.5rem;
  }

  .top-header {
    padding-top: 80px;
  }

  .landing h2 {
    font-size: 1.5rem;
  }

  section p {
    font-size: 1rem;
  }

  .popping-image {
    display: none;
  }

  .text-center {
    text-align: center !important;
  }

  .py-10 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .ada-font {
    font-size: 1.2rem;
  }

  .custom-modal-body {
    padding: 1rem;
  }

  .custom-submit-button {
    width: 100%;
  }
}
